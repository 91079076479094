<template>
    <div class="specification">
        <h1>Specification</h1>
        <div class="details">
            <div class="item" v-for="(item, i) in data" :key="i">
                <img :src="require('@/assets/images/spec/' + item.pic)">
                <div>
                    <h4>{{ item.label }}</h4>
                    <p v-for="(desc, _) in item.desc" :key="_">{{ desc }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProdSpec',
    props: {
        data: Array
    },
    data() {
        return {

        }
    }
}
</script>
<style scoped lang="less">
.specification {
    background-image: url("../assets/images/prods/pdsbg.png");
    padding: 100px 0;
	background-size: 100% 100%;
	
}

h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
}

.details {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-evenly;

    padding: 0 12vw;
    margin-top: 120px;
}

.item {
    color: #fff;
    display: flex;
	margin-bottom: 60px;
	// background-color: aqua;

    img {
        width: 32px;
        height: 32px;
        margin-right: 15px;
    }

    h4 {
        font-size: 26px;
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
    }
}
</style>